@import url("https://fonts.cdnfonts.com/css/poppins");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
z body:lang(en) {
  font-family: "Poppins", sans-serif;
}

body,
body:lang(ar) {
  font-family: "cairo", sans-serif;
}

.nav-link.active {
  color: #e9942a;
}

.responsive-padding {
  @apply px-10 md:px-10;
}

.ticker {
  overflow: hidden !important;
  animation: ticker 43s linear infinite;
}

.ticker-rev {
  overflow: hidden !important;
  animation: ticker-rev 43s linear infinite;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100vw);
  }
}

@keyframes ticker-rev {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

.second-ticker {
  overflow: hidden !important;
  animation: second-ticker 56s linear infinite;
}

.second-ticker-rev {
  overflow: hidden !important;
  animation: second-ticker-rev 56s linear infinite;
}

@keyframes second-ticker {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes second-ticker-rev {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100vw);
  }
}
